import { Component, OnInit } from '@angular/core';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { LeadOpportunitiesService } from '../../../contractaggreement/services/lead-opportunities.service';
import { saveAs as importedSaveAs } from "file-saver";
import { environment } from '../../../../../environments/environment';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationSettings } from '../../../common/application-settings';

@Component({
  selector: 'app-ltcf-contact-us-report',
  templateUrl: './ltcf-contact-us-report.component.html',
  styleUrls: ['./ltcf-contact-us-report.component.css']
})
export class LtcfContactUsReportComponent implements OnInit {

  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  loading: boolean;
  lgRData: any = [];
  // ddlFormat: string = '0';
  canExport: boolean = true;
  ddlSeason: number;
  outreachStartDate = ApplicationSettings.outreachStartDateIP;
  ltcfContactUsReportForm: FormGroup;
  constructor(private leadService: LeadOpportunitiesService, private ng4LoadingSpinnerService: NgxSpinnerService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.ltcfContactUsReportForm = this.formBuilder.group({
      ddlFormat: ['xlsxv1', Validators.required],      
    });
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
    this.generateReport();
  }

  generateReport(): void {
    this.loading = true;
    let leadModel = {isCurrentSeason: 1, isCovid: true, fromDate: new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason)), toDate: new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason)), covidOpportunityTYpe:1};
    this.leadService.getContactUsReport(leadModel).subscribe((resultArray: any) => {      
      this.lgRData = resultArray;
      this.lgRData.forEach(element => {              
        if (element.needClinicAtFacilityLTCF == false) {          
          element.needClinicAtFacilityLTCF = "Clinic at your facility";
        }
        else if (element.needClinicAtFacilityLTCF == true) {
          element.needClinicAtFacilityLTCF = "Walgreens Store";
        }

        if (element.isYesClickedByStore == false) {
          element.isYesClickedByStore = "No";
        }
        else if (element.isYesClickedByStore == true) {
          element.isYesClickedByStore = "Yes";
        }
        
      });

      this.loading = false;
      this.canExport = true;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.ng4LoadingSpinnerService.hide();
      this.lgRData = [];
    });
  }
  generateReportFormat(): void {

    if (this.ltcfContactUsReportForm.value&&this.ltcfContactUsReportForm.value.ddlFormat == '0') {
      return;
    }
    console.log('line 54');
    this.loading = true;
    this.leadService.exportLTCFReportData().subscribe((res:any) => {
      importedSaveAs(res.body, 'LTCF ContactUs.xlsx');
      this.loading = false;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }

  // generateReportFormat2(): void {
  //   this.loading = true;
  //   if (this.ddlFormat == '0') {
  //     return;
  //   }
  //   if (this.ddlFormat == "xlsxv1") {
  //     this.leadService.exportReportDataV2().subscribe(res => {
  //       //importedSaveAs(res, this.scheduleclinicreport.filename);
  //       var dat = res.json();
  //       if (dat) {
  //         let url = environment.API_URL + "/" + dat;
  //         window.open(url);
  //       }
  //       this.loading = false;

  //     }, error => {
  //       this.loading = false;
  //       this.showDialog("Error", ErrorMessages["error"], true);
  //     });
  //   }
  // }
  // validateExportType() {
  //   if (this.ltcfContactUsReportForm.controls["ddlFormat"].value == "0" || !this.canExport)
  //     return true;
  //   else
  //     return false;
  // }
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }

}
