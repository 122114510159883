
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { Router } from '@angular/router';
import { SessionDetails } from '../../../utility/session';
import { recaptchaRequest } from '../../../models/localLeadsSave';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable()
export class LeadOpportunitiesService {  

  constructor(private _http: HttpClient, private router: Router) { }

  saveLeadOpportunitesEnquiries(model) {
    let url = environment.API_URL + environment.RESOURCES.SAVE_LEAD_ENQUIRIES;
    let data = JSON.stringify(model);
    return this._http.post(url, data, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  getContactUsReport(leadModel) {
    let url = environment.API_URL + environment.RESOURCES.GET_CONTACTUS_REPORT;
    return this._http.post(url,leadModel, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));}

  siteVerify(request:recaptchaRequest) {
    let url = environment.API_URL + environment.RESOURCES.VERIFY_RECAPTCHA;
    return this._http.post(url, JSON.stringify(request), Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  private handleError(error: Response) {
    return observableThrowError("Error in Service:" + error);
  }

  public exportReportData(leadModel) {
    let userData = SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);
    let headers = new HttpHeaders()               
    .set("Content-Type", "application/json")
    .set('Authorization', 'Basic ' + usernamePassword);
    let url = environment.API_URL + "/api/JsContctUs_downloadReport";
    return this._http.post(url, leadModel, {headers: headers, observe:'response' as 'body',responseType:'blob' as 'json'}).pipe(catchError(Util.handleErrorV2));
  }
  public exportReportDataV2() {
    let headers: Headers = new Headers();
    let userData = SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    let url = environment.API_URL + "/api/JsContctUs_downloadReport"
    return this._http.get(url,{headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    .pipe(
      catchError(Util.handleErrorV2)
      );
      
  }
 public exportLTCFReportData() {
    let headers: Headers = new Headers();
    let userData = SessionDetails.GetUserInfo()
    console.log('line 61 exportLTCFReportData');
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    console.log('line 66 exportLTCFReportData');
    let url = environment.API_URL + "/api/JsLTCFContctUs_downloadReport"
    console.log('line 68 exportLTCFReportData');
    return this._http.get(url,{headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    .pipe(
      catchError(Util.handleErrorV2)
      );
      
  }
}