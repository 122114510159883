import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StoreprofileuploaderService } from '../services/storeprofileuploader.service';
import { NgxSpinnerService } from "ngx-spinner";
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-storeprofilereportandupdate',
  templateUrl: './storeprofilereportandupdate.component.html',
  styleUrls: ['./storeprofilereportandupdate.component.css'],
  providers: [StoreprofileuploaderService]
})
export class StoreprofilereportandupdateComponent implements OnInit {

  uploadedFileKey: string = "";
  uploadMode: number = -1;

  generatedStoreProfileData: any[] = [];
  cols: any[];
  fileCounts: any[];

  //canShowAnAction: boolean = false;
  currentAction: string = "";
  canShowActions: boolean = false;
  canShowConfirmPop: boolean = false;
  canShowEditStorePop: boolean = false;
  confirmMessage: string = "";
  dialogMessage: string = "";
  showInfoMsg: boolean = false;
  selectedStore: any = {};
  selectedStoreString: string = "";
  selectedStoreBkp: any = {};
  selectedRow: number;
  onSiteLocationSelectedValues: any = [];
  canOpenForEdit: boolean = false;
  @Output() loadPage = new EventEmitter<any>();

  @Output() loadEditForm = new EventEmitter<any>();

  constructor(private readonly spinner: NgxSpinnerService, private readonly store_profile_uploader_service: StoreprofileuploaderService) { }

  ngOnInit() {
    this.canOpenForEdit = (Number(this.uploadMode) === 1);
    this.cols = [
      { field: 'changeType', header: 'Change Type' },
      { field: 'updatedValue', header: 'Updated Value' },
      { field: 'storeId', header: 'Store ID' },
      { field: 'address', header: 'Address' },
      { field: 'city', header: 'City' },
      { field: 'state', header: 'State' },
      { field: 'zip', header: 'Zip' },
      { field: 'districtNumber', header: 'District' },
      { field: 'districtName', header: 'District Name' },
      { field: 'regionNumber', header: 'Region' },
      { field: 'regionName', header: 'Region Name' },
      { field: 'areaNumber', header: 'Area' },
      { field: 'areaName', header: 'Area Name' }
    ];
    if (Number(this.uploadMode) === 1) {
      //Removing Change Type and Updated Value
      //this.cols.splice(0, 2);
      let onsite_col = { field: 'onSiteLocation', header: 'On-site Location' };
      let wag_rad_store = { field: 'wagRadStore', header: 'WAG/RAD Store' };
      this.cols.splice(0, 0, onsite_col, wag_rad_store);
    }
    if (this.generatedStoreProfileData.length > 0) {
      this.generatedStoreProfileData.forEach(ele => {
        ele.onSiteLocation = JSON.parse(ele.onSiteLocation);
      });
    }
    //if (this.uploadMode == 0) {
    this.spinner.show();
    this.getGeneratedStoreProfileReport();
    // }
  }

  getGeneratedStoreProfileReport() {
    this.store_profile_uploader_service.getGeneratedStoreProfileReport(this.uploadedFileKey, this.uploadMode).subscribe((result:any) => {
      this.generatedStoreProfileData = result.storeProfileList;
      let index = 0
      this.generatedStoreProfileData.forEach(obj => {
        let k: boolean = false;

        if (obj.onSiteLocation) {
          this.onSiteLocationSelectedValues.push(index);
          k = JSON.parse(obj.onSiteLocation);
        }
        obj.onSiteLocation = k;
        index++;
      });
      this.fileCounts = result.uploadedFilesStatsList;
      //console.log(this.generatedStoreProfileData);
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        if (error.status === 417) {
          this.dialogMessage = "HTTP/1.1 417 Expectation Failed";
        } else {
          this.dialogMessage = "An error occurred. Please concact administrator.";
        }
        this.showInfoMsg = true;
      });
  }
  getHeaderClass(e: string) {
    let cls_name = "";
    switch (e) {
      case 'Store ID':
      case 'State':
      case 'Zip':
      case 'District':
      case 'Region':
      case 'Area':
        cls_name = 'w100';
      break;
      case 'On-site Location':
      case 'WAG/RAD Store':
      case 'Updated Value':
          cls_name = 'w155'
        break;
      case 'Change Type':
        cls_name = 'w100';
        break;
      case 'District Name':
      case 'Area Name':
      case 'Region Name':
        cls_name = 'w200'
        break;
      case 'Address':
        cls_name = 'w350';
        break;
      case 'City':
        cls_name = 'w200';
        break;
      default:
        cls_name = '';
        break;
    }
    return cls_name;
  }

  backNav() {
    let emit_json = {
      "uploadedOn": "",
      "stepNumber": 1
    };
    this.loadPage.emit(emit_json);
  }

  showActionsDialog(index: number) {
    this.selectedStore = this.generatedStoreProfileData[index];
    this.selectedStoreString = JSON.stringify(this.selectedStore);
    sessionStorage.setItem('resetOnCancelUpdate', JSON.stringify(this.selectedStore));
    this.selectedRow = index;
    this.canShowActions = true;
  }

  continueOnConfirm() {
    switch (this.currentAction) {
      case "remove":
        this.currentAction = "";
        this.canShowConfirmPop = false;
        //Call the service to update in database;
        this.store_profile_uploader_service.deleteStoreProfile(this.uploadedFileKey, this.selectedStore.storeId).subscribe(result => {
          this.dialogMessage = "Store deleted successfully."
          this.removeStoreSettings(false);
        },
          error => {
            this.spinner.hide();
            if (error.status === 417) {
              this.dialogMessage = "HTTP/1.1 417 Expectation Failed";
            } else {
              this.dialogMessage = "Could not delete a Store... Please contact administrator.";
            }
            this.removeStoreSettings(true);
          });
        break;
      case "updateSite":
        console.log(this.generatedStoreProfileData);
        this.canShowConfirmPop = false;
        this.currentAction = "";
        this.spinner.show();
        this.store_profile_uploader_service.updateStoreProfilesInSite(this.uploadedFileKey, this.uploadMode).subscribe((result:any) => {
          let emit_json = {
            "uploadedOn": this.uploadedFileKey,
            "stepNumber": 3,
            "uploadMode": this.uploadMode
          };
          if (result.errorS && result.errorS.errorMessage !== "") {
            this.dialogMessage = result.errorS.errorMessage;
            emit_json["completedStatusMsg"] = this.dialogMessage;
          }
          this.spinner.hide();
          this.loadPage.emit(emit_json);
          //navigate to new step component.
        }, error => {
          this.spinner.hide();
          if (error.status === 417) {
            this.dialogMessage = "HTTP/1.1 417 Expectation Failed";
          } else {
            this.dialogMessage = "Could not update stores... Please contact administrator.";
          }
          this.showInfoMsg = true;
        });
        /*let emit_json = {
          "uploadedOn": this.uploadedFileKey,
          "stepNumber": 3,
          "uploadMode": this.uploadMode
        };*/
        /*this.spinner.hide();
        this.loadPage.emit(emit_json);*/
        break;
      default:
        break;
    }
  }

  removeStoreSettings(is_error: boolean) {
    this.cancelOnConfirm();
    this.showInfoMsg = true;
    if (!is_error) {
      this.getGeneratedStoreProfileReport();
    }
    this.spinner.hide();
  }

  cancelOnConfirm() {
    this.selectedRow = -1;
    this.selectedStore = {};
    sessionStorage.removeItem('resetOnCancelUpdate');
    this.canShowConfirmPop = false;
  }

  cancelUpdate() {
    //this.selectedStore = JSON.parse(sessionStorage.resetOnCancelUpdate);
    this.selectedStore = {};
    this.generatedStoreProfileData[this.selectedRow] = JSON.parse(sessionStorage.getItem("resetOnCancelUpdate"));
    sessionStorage.removeItem('resetOnCancelUpdate');
    this.canShowEditStorePop = false;
    this.selectedRow = null;
  }

  updateStore() {
    //Need to call service to update tblStoreProfilesUploadData table for Full upload.
    //In case of partial upload,(need to call the service while uploading which returns the feed data only and it does not insert into tblStoreProfilesUploadData),
    // need to update the data in the grid only and should not be posted to service as it is for full upload.
    this.generatedStoreProfileData[this.selectedRow] = this.selectedStore;
    this.store_profile_uploader_service.updateAStoreProfile(this.uploadedFileKey, this.selectedStore.storeId, this.selectedStore, this.uploadMode).subscribe(result => {
      this.dialogMessage = "Store updated successfully."
      this.updateStoreSettings(false);
    }, error => {
      if (error.status === 417) {
        this.dialogMessage = "HTTP/1.1 417 Expectation Failed";
      } else {
        this.dialogMessage = "Store Update failed... Please contact administrator.";
      }
      this.updateStoreSettings(true);
    });

    sessionStorage.removeItem('resetOnCancelUpdate');
    this.canShowEditStorePop = false;
    this.selectedRow = null;
  }

  updateStoreSettings(is_error: boolean) {
    this.canShowEditStorePop = false;
    this.showInfoMsg = true;
    if (!is_error) {
      this.getGeneratedStoreProfileReport();
    }
    this.spinner.hide();
  }

  updateSite() {
    this.currentAction = "updateSite";
    this.confirmMessage = 'This action updates the stores in the site and cannot be undone. Click "Continue" to update.';
    this.canShowConfirmPop = true;
  }

  getCheckboxValue(rowIndex: number, attr: string, event: any) {
    console.log(rowIndex);
  }

  setIsOnsiteStore(rowIndex: number, attr: string, event: any) {
    this.generatedStoreProfileData[rowIndex][attr] = event.target.checked;
    this.selectedRow = rowIndex;
    this.selectedStore = this.generatedStoreProfileData[rowIndex];
    this.updateStore()
  }

  setValue(rowIndex: number, attr: string, event: any) {

  }

  getValue(value: string): boolean {
    return JSON.parse(value);
  }

  getButtonContent(action: string) {
    let but_content: string = "";
    switch (action) {
      case "openOrEdit":
        but_content = 'Edit Store';
        break;
      case 'remove':
        but_content = 'Remove Store';
        break;
      default:
        break;
    }
    return but_content;
  }

  getHeader() {
    let dlg_header = "";
    switch (this.currentAction) {
      case "remove":
        dlg_header = "Remove Store";
        break;
      case "updateSite":
        dlg_header = "Update Site";
        break;
      default:
        break;
    }
    return dlg_header;
  }

  makeAction(action: string) {
    switch (action) {
      case "openOrEdit":
        this.canShowActions = false;
        this.selectedStore = this.generatedStoreProfileData[this.selectedRow];
        this.selectedStoreString = JSON.stringify(this.selectedStore);
        //this.canShowEditStorePop = true;
        let emit_json = {
          "uploadedOn": this.uploadedFileKey,
          "uploadMode": this.uploadMode,
          "selectedStore": this.selectedStore
        };
        this.loadEditForm.emit(emit_json);
        break;
      case "remove":
        //Get the user confirmation before removing.
        this.canShowActions = false;
        this.currentAction = "remove";
        this.confirmMessage = 'Removing the store cannot be undone. If you "Continue" the store will be permanently deleted.';
        this.canShowConfirmPop = true;
        break;
      default:
        break;
    }
  }

  Ok() {
    this.showInfoMsg = false;
  }

  downloadReport() {
    this.spinner.show();
    this.store_profile_uploader_service.downloadStoreProfileUploadReport(this.uploadedFileKey, this.uploadMode).subscribe((res:Response) => {
      let fileName:string = res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];
      importedSaveAs(res.body,"StoreUpdatesReport_"+this.uploadedFileKey+fileName.substring(fileName.indexOf('.')));
      this.spinner.hide();
    },
      error => {
        if (error.status === 417) {
          this.dialogMessage = "HTTP/1.1 417 Expectation Failed";
        } else {
          this.dialogMessage = "An error occurred. Please concact administrator.";
        }
        this.showInfoMsg = true;
      });
  }

  disableOnSiteOptionForRadStore(store: any) {
    return (store.wagRadStore === 'RAD');
  }

}
