import { Component, OnInit, Compiler } from '@angular/core';
import { Router } from '@angular/router';
import { AppCommonSession } from '../../../common/app-common-session';
import { SessionDetails } from '../../../../utility/session';
import { userLoginData } from '../../../../JSON/userLoginData';
import { UserLoginInfo } from '../../../../models/userLoginInfo';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { UserInfo } from '../../../../models/userInfo';
import { EncryptLinkService } from '../../services/encrypt-link.service';
import { FormGroup, FormBuilder } from '../../../../../../node_modules/@angular/forms';
import { Util } from '../../../../utility/util';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { SendUserAccessLink, UserAccessLinkUserList } from '../../../../models/userAccessLink';
import { environment } from '../../../../../environments/environment';
import { Alert, AlertType } from  '../../../../../app/models/Alert';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  users = [];
  usersInfo = [];
  loginError: boolean = false;
  RoleID: string;
  contractpk: number;
  storeID: number;
  userName: string;
  password: string;
  loadAPI: Promise<any>;
  email_address: any = null;
  encryptForm: FormGroup;
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  displayMultipulRoles: boolean = false;
  dispalyMultipleRolesMessage:string;
  multipleRoles:any=[];
  selectedRole:number;
  sendaccessPassword:string;
  ShowSendAccess: boolean = false;
  removeSendAccessInfo: any = true;
  constructor(private router: Router, private formBuilder: FormBuilder,
    private encrypt_link: EncryptLinkService, private commonsession: AppCommonSession, private utility: Util,
    private authservice: AuthenticationService, private compiler: Compiler) {
    this.compiler.clearCache();
    this.loadAPI = new Promise((resolve) => {
     //  this.loadScript();
      resolve(true);
    });

    //  if(environment.isSiteDeploying){
    //     this.router.navigateByUrl('/maintenance');
    //   }
  }

  ngOnInit() {     
    this.removeSession();
    if (sessionStorage.unauth) {
      this.loginError = true;
    }
  }
 
    removeAlert() {
    this.removeSendAccessInfo = false;
  }

  OpenaccessLinkPDF(){
 
    window.open("http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/offsite_clinics/Outreach_Portal_Access.pdf", "_blank");
  }
  removeSession() {
    sessionStorage["roleID"] = null;
    sessionStorage["storeId"] = null;
    sessionStorage.removeItem("hintsOff");
    sessionStorage["aP"] =null;
    sessionStorage["cP"] =null;
  }
  //It verifies the USer and navigate to get the landing page based on the role.
  getDashboard() {
    var user_info = new UserLoginInfo();
    user_info.userName = this.userName;
    user_info.password = this.password;
    SessionDetails.SetUserInfo(user_info);
    SessionDetails.SetStoreOppSortByDefaultVal("0");
    this.authservice.getUserProfile().subscribe((data: any) => {
      if (data.dataList.length > 0) {
        var user_profile = new UserInfo();
        user_profile.email = data.dataList[0].email;
        user_profile.userName = data.dataList[0].userName;
        user_profile.userPk = data.dataList[0].userPk;
        user_profile.userRole = data.dataList[0].userRole;
        user_profile.isAdmin = data.dataList[0].isAdmin;
        user_profile.isPowerUser = data.dataList[0].isPowerUser;
        user_profile.firstName = data.dataList[0].firstName;
        user_profile.lastName = data.dataList[0].lastName;
        user_profile.locationType = data.dataList[0].locationType;
        user_profile.assignedLocations = data.dataList[0].assignedLocations;
        SessionDetails.SetUserProfile(user_profile);
        sessionStorage.removeItem("unauth");
        this.router.navigate(['/landing']);
        window.scrollTo(0, 0);
      }
    }, err => {
      if (err.status == 401) {
        SessionDetails.SetUserInfo(null);
        console.log(err.statusText);

      }
      this.loginError = true;
      sessionStorage["unauth"] = "unauth";
    });


  }
  getUserInfo(pk) {
    let user_Data = userLoginData;
    let login_Info = user_Data.filter(x => x.pk == pk)[0];
    login_Info.userName=SessionDetails.getD(SessionDetails.absJ,login_Info.userName);
    login_Info.password=SessionDetails.getD(SessionDetails.absJ,login_Info.password);
    login_Info.role=SessionDetails.getD(SessionDetails.absJ,login_Info.role);
    return login_Info;
  }
  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') !== null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = ["../../../../../assets/js/scripts.js"];

      for (let i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }
  generateLink(userPk:number) {
    let sumMsg = null;
    let sMsg = null;
    //let user_access_link = new SendUserAccessLink();
    let userAccessLinkUserList = new UserAccessLinkUserList();
    userAccessLinkUserList.userPk = 41;
    userAccessLinkUserList.email = this.email_address;
    let data = this.getUserInfo(41);
    var user_info = new UserLoginInfo();
    user_info.pk = data.pk;
    user_info.userName = data.userName;
    user_info.password = data.password;
    user_info.role = data.role;
    SessionDetails.SetUserInfo(user_info);
    if (this.email_address !== null && this.email_address !== '') {
      if (this.utility.validateEmail(this.email_address)) {
        var email = this.email_address;
        var user_pk = 0;
        if(userPk) user_pk = userPk;
        this.encrypt_link.getEncryptLink(user_pk, email).subscribe((data:any) => {
          userAccessLinkUserList = data.userAccessLinkUserList;
          switch (data.errorS.errorCode) {
            case 0:
              sumMsg = ErrorMessages['sendAcessLink'];
              sMsg = "An email with the access link has been sent to the email address provided";
              this.showDialog(sumMsg, sMsg, false);
              return;
            case -1:
              sumMsg = ErrorMessages['sendAcessLink'];
              sMsg = "The email you have entered does not exist in the portal.<br/><br/>Unless you are supporting multiple roles, the email used by the portal will be your alias email, not your personal email.<br/><br/>Please contact a site administrator, if neither your alias email nor your personal email sends you an access link.";
              this.showDialog(sumMsg, sMsg, false);
              return;
            case -2:
              this.dialogMsg = data.errorS.errorMessage;
              this.multipleRoles=data.userAccessLinkUserList;
              this.dialogSummary=ErrorMessages['sendAcessLink'];    
              this.displayMultipulRoles=true;
              return;
          }
        });
      }
      else {
        let sumMsg = ErrorMessages['sendAcessLink'];
        let sMsg = ErrorMessages['sendEmailMessgae'];
        this.showDialog(sumMsg, sMsg, false);
      }

    }
    else {
      let sumMsg = ErrorMessages['errMsg'];
      let sMsg = "Please enter email address";
      this.showDialog(sumMsg, sMsg, false);
    }
  }


  showDialog(msgSummary: string, msgDetail: string, success_message: boolean) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  okClicked() {
    this.display = false;
    this.email_address = '';
  }
  sendMutipulEmails() {
    this.generateLink(this.selectedRole);
    this.displayMultipulRoles = false;
  }
  Close() {
    this.displayMultipulRoles = false;
    this.selectedRole=0;
  }
}
